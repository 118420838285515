import {PropsWithChildren, useContext, useState} from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import styles from "./styles/LoginButtons.module.scss";
import JSONWebToken from "../../../../utils/JSONWebToken";
import { StoreContext } from "../../../../stores/StoreLoader";
import { getConfig } from "../../../../_configs/AppConfig";
import useAsyncEffect from "../../../../hooks/useAsyncEffect";
import URI from "urijs";
import {getCookieFromString} from "../../../../utils/StringUtilities";
import {IView} from "../../../../stores/InterfaceStore";

function ComponentToDisplay(props) {
    if (props.onClick) {
        return <button onClick={props.onClick} aria-label={props.item.label}>{props.children}</button>
    } else {
        return <a type={"button"} href={props.item.href} aria-label={props.item.label}>{props.children}</a>;
    }
}

const {publicRuntimeConfig} = getConfig();

type LoginButtonType = "google" | "facebook" | "office" | "facebookAdmin" | "googleAdmin" | "passport";

const LoginButton = observer((props: PropsWithChildren<{
    type: LoginButtonType,
    onClick?: () => void,
}>) => {
    const {interfaceStore, i18nStore, organizationStore} = useContext(StoreContext);
    const [buttonData, setButtonData] = useState<any>(null);

    const baseLoginUrl = interfaceStore.isSchoolBlocks ? "" : `https://login.${publicRuntimeConfig.APP_ENV}.schoolblocks.com`;
    const baseWatsonLoginUrl = `https://api.${publicRuntimeConfig.APP_ENV}.cleversite.com/authentication/`;

    useAsyncEffect(async () => {
        const jwt = await new JSONWebToken().encode(
            organizationStore.isMyCleverFeed ? undefined : organizationStore.organization.id,
            interfaceStore.view === IView.LOGIN ? window.location.origin : interfaceStore.currentFullUrl,
        );
        setButtonData({
            google: {
                label: "Sign in with Google",
                href: URI(baseWatsonLoginUrl + "google-oauth2/").search({
                    state: jwt,
                }).normalize().toString()
            },
            googleAdmin: {
                label: "Sign in with Google",
                href: URI(baseWatsonLoginUrl + "google_admin_login/").search({
                    state: jwt,
                }).normalize().toString(),
            },
            office: {
                label: "Sign in with Office 365",
                href: URI(baseWatsonLoginUrl + "microsoft-graph/").search({
                    state: jwt,
                }).normalize().toString()
            },
            facebook: {
                label: "Sign in with Facebook",
                href: URI(baseWatsonLoginUrl + "facebook/").search({
                    state: jwt,
                }).normalize().toString()
            },
            facebookAdmin: {
                label: "Sign in with Facebook",
                href: URI(baseWatsonLoginUrl + "facebook_admin_login/").search({
                    state: jwt,
                    apiKey: getCookieFromString("token", document.cookie),
                }).normalize().toString(),
            },
            passport: {
                label: "Sign in with Passport",
                href: URI(baseWatsonLoginUrl + "passport/").search({
                    state: jwt,
                }).normalize().toString()
            },
        })
    }, [organizationStore.organization.id, interfaceStore.currentFullUrl])

    const loginButtonClassName = classNames({
        [styles.loginButton]: true,
        [styles.loginButtonGoogle]: props.type === "google" || props.type === "googleAdmin",
        [styles.loginButtonOffice]: props.type === "office",
        [styles.loginButtonPassport]: props.type === "passport",
        [styles.loginButtonFacebook]: props.type === "facebook" || props.type === "facebookAdmin",
    });
    const loginButtonLogoContainerClassName = classNames({
        [styles.loginButtonLogoContainer]: true,
    });
    const loginButtonLogoClassName = classNames({
        [styles.loginButtonLogo]: true,
        [styles.loginButtonLogoGoogle]: props.type === "google" || props.type === "googleAdmin",
        [styles.loginButtonLogoOffice]: props.type === "office",
        [styles.loginButtonLogoPassport]: props.type === "passport",
        [styles.loginButtonLogoFacebook]: props.type === "facebook" || props.type === "facebookAdmin",
    });
    const loginButtonTextClassName = classNames({
        [styles.loginButtonText]: true,
        [styles.loginButtonTextGoogle]: props.type === "google" || props.type === "googleAdmin",
        [styles.loginButtonTextOffice]: props.type === "office",
        [styles.loginButtonTextPassport]: props.type === "passport",
        [styles.loginButtonTextFacebook]: props.type === "facebook" || props.type === "facebookAdmin",
    });

    if (!buttonData) {
        return null;
    }

    const item = buttonData[props.type];

    return (
        <ComponentToDisplay onClick={props.onClick} item={item}>
            <div className={loginButtonClassName}>
                <div className={loginButtonLogoContainerClassName}>
                    <div className={loginButtonLogoClassName} />
                </div>
                <div className={loginButtonTextClassName}>
                    {item.label}
                </div>
            </div>
        </ComponentToDisplay>
    );
})

export default LoginButton;
